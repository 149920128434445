.brand-enter {
  opacity: 0;
}

.brand-enter.brand-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.brand-leave {
  opacity: 0;
}

.brand-leave.brand-leave-active {
  opacity: 0;
}

.App {
  transition: all 1s ease;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden
}

.intro {
  display: flex;
  align-items:center;
  height: 100vh;
}

a {
  color: black
}

.list-container {
  display: flex
}

.navbar {
  padding-left: 5vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  background-color: white
}

.headroom--unfixed {
  position: absolute !important;
}

.slick-prev:before, .slick-next:before { 
  color:blue !important;
}

.pill-button:hover {
  color: white;
}

.skill-card {
  background-color: white;
  margin-right: 5px;
  word-wrap: break-word;
  border: 1px solid #4C516D;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.child {
  z-index: 1;
  position: absolute;
  bottom:30%;
  margin-left: 4%;
  font-size: 1.15em
}

img.mockup {
  width: 100%;
}

img.profile {
  width: 75%;
  border-radius: 50%;
}

.headroom--pinned, .headroom--unfixed {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

p, ul {
  font-size: 16px;
}

.start-button {
  position: absolute;
  width: 100vw;
  text-align: center;
}

.proj-desc, .about-desc {
  text-align: justify;
  margin-top: 0;
  padding-right: 5px;
}

.link {
  text-decoration: underline;
}

.footer {
  position: relative;
  bottom: 0;
  box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.2);
}

@media only screen and (max-width: 767px) {
  .drawer {
    background-color: #89CFF0;
  }

  .footer {
    font-size: 0.8em;
    padding: 0 5px 0 5px;
  }

  .projects, .about, .blog {
    padding-right: 5vw;
  }

  .start-button {
    bottom: 15vh;
  }

  .navbar {
    padding-right: 5vw
  }

  .welcome > h3 {
    font-size: 16pt
  }

  .welcome > h4 {
    font-size: 14pt
  }

  .welcome {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .conList {
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    width: 90%
  }

  .list-container {
    justify-content: center;
    margin-top: 0.8rem
  }

  .title {
    font-size: 1.5rem
  }
  .name {
    font-size: 1.7rem
  }
  .fab {
    font-size: 1.45rem
  }

  .skill-card {
    height: 26vh;
    width: 25vw;
  }
  .pill-button {
    padding: 5px !important;
    font-size: 0.8em
  }

  .section {
    margin-bottom: 40px;
  }

  .edu, .exp {
    padding-right: 5vw;
  }
  
  .blog-container {
    flex-wrap: wrap;
    gap: 10px;
  }
}


@media only screen and (min-width: 768px) {
  .footer {
    width: 100vw;
  }
  .App {
    padding-right: 5vw;
  }

  .start-button {
    bottom: 10vh;
  }

  .conList {
    flex-direction: column;
  }

  .conList > div {
    margin-bottom: 15px
  }

  .section {
    margin-bottom: 35px;
  }

  .skill-card {
    height: 20vh;
    width: 20vw;
    transition: all 300ms ease-in
  }

  .list-container {
    justify-content: flex-end;
  }
  
  .title {
    font-size: 1.7rem
  }

  .name {
    font-size: 1.9rem
  }

  .fab {
    font-size: 2rem
  }

  .navbar > .menu {
    justify-content: space-around
  }

  .welcome {
    width: 100%;
  }

  .pill-button {
    font-size: 1em
  }

  .blog-container {
    gap: 20px;
  }
}
